<template>
    <!--<v-row>
    <v-col>
        <v-app-bar v-model="appBar">
    
            <v-spacer></v-spacer>
            <v-toolbar-title>
            Aplicaciones Atika
            </v-toolbar-title>
        </v-app-bar>
        
    </v-col>
    </v-row>-->
    <!--<div class="appLogin white-fondo">
            <div class="edificio">
            <v-container class="alMedio login">
                <v-card elevation="24">
                    <v-card-title>
                        <span class="texto_centrado">
                            Ingreso de usuarios
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-form
                            @submit.prevent="submit">
                        <v-text-field
                            v-model="objLogin.usuario"
                            label="nombre de usuario Atika"
                            hint="Coloque su nombre de correo atika antes de la @"
                            required
                            :rules="reglas"
                            >
                        </v-text-field>
                        <v-text-field
                            v-model="objLogin.pass"
                            label="Contraseña"
                            hint="Use la misma contraseña que usa para ingresar a su equipo."
                            required
                            @keydown.enter="validaUsuario"
                            type="password">
                        </v-text-field>
                        </v-form>


                    </v-card-text>
                    <v-card-actions class="mt-auto">
                        <v-btn
                            class="maximum_red-fondo white-texto"
                            :disabled="!validarForm"
                            v-on:click="validaUsuario"
                            block
                            >
                            Ingresar a plataforma
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
            <v-snackbar v-model="snackbar" color="red darken-4">
                {{snackText}}
            </v-snackbar>
            </div>
            
        </div>-->
    <div class="edificio">
        <div class="container" :class="{ active: isActive }" id="container">
            <div class="form-container sign-up">
                <form>
                    <h1>Create Account</h1>
                    <span>or use your email for registration</span>
                    <input type="text" placeholder="Name">
                    <input type="email" placeholder="Email">
                    <input type="password" placeholder="Password">
                    <button>Sign Up</button>
                </form>
            </div>
            <div class="form-container sign-in">
                <form @submit.prevent="validar()">
                    <h1>Ingreso</h1>
                    <br>
                    <span>Coloque su nombre de correo atika antes de la @</span>
                    <input type="text" placeholder="usuario" v-model="objLogin.usuario">
                    <h5 class = "maximum_red-texto" v-if = "submited && !$v.objLogin.usuario.required" >Este campo es obligatorio</h5>
                    <input type="password" placeholder="Contraseña" v-model="objLogin.pass">
                    <h5 class = "maximum_red-texto" v-if = "submited && !$v.objLogin.pass.required" >Este campo es obligatorio</h5>
                    <button type="submit">Ingresar</button>
                </form>
            </div>
            <div class="toggle-container">
                <div class="toggle">
                    <div class="toggle-panel toggle-left">
                        <h1>Welcome Back!</h1>
                        <p>Enter your personal details to use all of site features</p>
                        <button @click="toggleActive" id="login">Login</button>
                    </div>
                    <div class="toggle-panel toggle-right">
                        <h2>Bienvenido a Atika!</h2>
                        <p>Esta es tu plataforma de gestión y visualización de datos.</p>
                        <!--<button @click="toggleActive" id="register">Register</button>-->
                    </div>
                </div>
            </div>
            <v-snackbar 
                v-model="snackbar"
                shaped
                color='#FFFFFF'>
                <h4 class="black-texto">{{snackText}}</h4>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import axios from 'axios' 
import HeaderLogin from '@/components/globales/headers/HeaderLogin.vue'
import {required} from 'vuelidate/lib/validators';
import Indicadores from '@/components/dashboard/Indicadores.vue'


export default {
    name: "Login",
    data() {
        return {
            submited: false,
            isActive: false,
            appBar:true,
            objLogin: {
                usuario: "",
                pass: ""
            },
            snackbar: false,
            snackText: "",
            reglas: [val => (val || "").length > 0 || "El campo es requerido"]
        };
    },
    components: {  Indicadores},
    /*created: {
    
    },
    computed() {
        validarForm() {
            return (this.objLogin.usuario && this.objLogin.pass);
        }
    },*/
    methods: {
        validar(){
            this.submited = true;

            if(this.$v.$invalid){
                return false;
            }else {     
                this.validaUsuario();
            }
        },
        validaUsuario() {
            this.snackbar = true;
            this.snackText = "Validando usuario";
            //let ruta=process.env.VUE_APP_RUTA_LOGIN + this.objLogin.usuario + "/" + this.objLogin.password
            let ruta=process.env.VUE_APP_RUTA_LOGIN 
            axios.post(ruta, this.objLogin).then(response => {
                if (response.data.activo==1) {
                    this.$cookies.set('idUsuario',response.data.id_usuario)
                    this.$cookies.set('id_usuario', JSON.stringify(response.data.id_usuario))
                    this.$cookies.set('nombreUsuario',response.data.nombre)
                    this.$cookies.set('login',this.objLogin.usuario)
                    this.$cookies.set('AppKey', '651ATK25')
                    this.$router.push({
                        name:"Home",
                        params:{usuarioLogin:response.data.nombre}
                    });
                    this.appBar = false;
                }
                else {
                    this.snackbar = true;
                    this.snackText = "Error : Usuario y/o contraseña incorrecta.";
                }
            });
        },
        toggleActive() {
            this.isActive = !this.isActive;
        }
    },
    validations:{                           //Instanciamienta de validaciones segun variable con libreria VueValidators
        objLogin:{
            usuario:{required},
            pass:{required},
        }
    }
}

</script>

<style scoped>
/* Add your styles here */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

/*body{
    background-color: #c9d6ff;
    background: linear-gradient(to right, #e2e2e2, #c9d6ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}*/
.edificio {

    /*background-image: linear-gradient(rgba(5, 7, 12, 0.25),rgba(5, 7, 12, 0.25)), url('/public/imagenes/login/edificioPrueba.jpg');*/  /*255,255,255*/
    background-image: linear-gradient(rgba(5, 7, 12, 0.25),rgba(5, 7, 12, 0.25)), url('/public/imagenes/login/edificio_vitacura.png'); 
    background-position: center;
    background-size: cover;
    width:100%;
    height:100vh;
    /*min-height:640px;*/
    position:absolute;
    top:0%;
    left: 0%;
    right:0%;
    bottom: 0%;
    z-index:-1;

    display: flex;
    align-items: center;
    justify-content: center;

}

.container{
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.container p{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.container span{
    font-size: 12px;
}

.container a{
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}

.container button{
    background-color: #BF1A2F; /*#512da8;*/
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.container button.hidden{
    background-color: transparent;
    border-color: #fff;
}

.container form{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.container input{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

.form-container{
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in{
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.active .sign-in{
    transform: translateX(100%);
}

.sign-up{
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.active .sign-up{
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move{
    0%, 49.99%{
        opacity: 0;
        z-index: 1;
    }
    50%, 100%{
        opacity: 1;
        z-index: 5;
    }
}

.toggle-container{
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.container.active .toggle-container{
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle{
    background-color: #BF1A2F; /*#512da8;*/ 
    height: 100%;
    background: linear-gradient(to right, #ed464f, #ed464f /*#5c6bc0, #512da8*/); 
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.container.active .toggle{
    transform: translateX(50%);
}

.toggle-panel{
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-left {
  transform: translateX(-200%);
}

.container.active .toggle-left {
  transform: translateX(0);
}

.toggle-right {
  right: 0;
  transform: translateX(0);
}

.container.active .toggle-right {
  transform: translateX(200%);
}
</style>
<!--

<style>
    .login {
        width:400px;
    }

    .alMedio {
    
    /*width: 200px;*/
    /*display: flex;*/
    align-items: center;
    justify-content: center;
	/*margin-top:12%;*/
	/*margin-left:35%;*/
    }

    .edificio {

        /*background-image:url('@/assets/edificio.jpg');*/
        background-image:url('/public/imagenes/login/edificioPrueba.jpg');
        background-position: center;
        background-size: cover;
        width:100%;
        height:100% !important;
        /*min-height:640px;*/
        position:absolute;
        top:0%;
        z-index:-1;
    }
</style>

-->