<template>
  <v-app id="atikaApp" class="h-100 w-100">
    <Header style="position: fixed; width: 100%"></Header>
    <v-container fluid grid-list-sm>
      <v-layout row wrap>
        <v-flex>
          <div class="iframe-container">
            <iframe 
            :src=marco 
            scrolling="no" 
            frameborder="0">
            </iframe>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
  
  <script>
  import Menu from '@/components/Menu.vue'
  import Header from '@/components/Header.vue'
  export default {
    name: 'TrazabilidadPedidos',
  
    components: { Menu,Header },
  
    data() {
      return {
        ruta: new URL(window.location.href),
        ref: '',
        marco: 'https://app.atika.cl/app-atika-trazabilidad-pedidos',
        idUsuario:this.$cookies.get("idUsuario"),
        key:this.$cookies.get('AppKey'),
      }
    },
  
    created() {
      let rutaActual = new URL(window.location.href);
      let idApp = rutaActual.searchParams.get('IA');
      this.marco = this.marco + '?idUsuario=' + this.idUsuario + '&appKey=' + this.key + '&idApp=' + idApp
      //console.log("ruta menu -> " + this.marco) 
    }
  }
  </script>

<style scoped>
.iframe-container {
  margin-top: 64px; /* Ajusta según la altura del header */
  width: 100%;
  height: calc(100vh - 64px); /* Resta la altura del header */
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>