<template>
    <div class="datePicker texto_label blue-cadet-texto">
        <datepicker
            v-model="campoFecha"
            width="150"
            class="selectorFecha"
            :format="'dd-MM-yyyy'"
            :language="es"
            :monday-first=true
            :highlighted="formatoFecha.resaltado"
            :disabled-dates="formatoFecha.deshabilitados"
            placeholder="Seleccione fecha">
        </datepicker>
    </div>

</template>

<script>

import Datepicker from 'vuejs-datepicker'
import {es} from 'vuejs-datepicker/dist/locale'
import axios from 'axios'

    export default
    {
        name:'fecha',

        components:{Datepicker,es},

        props:['campo'],

        data() {
            return {
                activePicker:null,
                menu:false,
                campoFecha:null,
                modal:false,
                dateFormatted: null,
                open:false,
                date:null,
                es:es,

                formatoFecha : {
                        resaltado: {
                        days:[0,6],
                        dates: [
                            new Date()
                            ],
                        },
                        deshabilitados: {

                        to: '', // Disable all dates up to specific date
                        },

                    }
                }
        },

        created() {
            this.restringeFecha()
        },

        watch: {
            campoFecha(value) {
              this.registraFecha()
            }
        },

        methods: {
            registraFecha() {
                this.$emit('registraFecha',this.campoFecha)
            },

            restringeFecha() {
                 axios.post(
                process.env.VUE_APP_RUTA_COMEX,{
                method:'post',
                accion:'consultaFecha',
                prefijo:this.campo
                }).then(response => {
                    //console.log("Respuesta axios")
                    //console.log(response.data)
                    this.formatoFecha.deshabilitados.to= new Date(response.data)
                    //this.formatoFecha.deshabilitados.to=new Date(this.toObject(response.data))
                })
            },

            toObject(arr) {
                var obj= {}
                for(var i=0; i<arr.length; ++i) {
                    if(arr[i] !== undefined) obj[i]=arr[i]
                }
                //console.log(obj)
                return obj
            }



        }
    }
</script>