<template>
<v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
            <h2 class="text-center">Videotutoriales</h2>
            <br>
            <v-row>
                <v-col v-for="video in dataVideos" style="display: flex; align-items: center; justify-content: center;">
                    <v-card width="300" class="videos" color="#efefef">
                        <v-card-text>
                            <v-img
                                round
                                class="sombraImagen"
                                height="250"
                                elevation="10"
                                :src="video.miniatura"
                                > <!--gradient="to top right, rgba(79, 93, 117, 0.5), rgba(255, 255, 255, 0.5)"-->
                            </v-img>
                        </v-card-text>
                        <h3>
                            {{ video.titulo }}
                        </h3>
                        <v-card-actions>
                            <v-btn
                            :href="video.url"
                            target="_blank" 
                                block 
                                class="maximum_red-fondo white-texto">
                                Ver video
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row style="justify-content: flex-end; margin-right: 2%; margin-bottom: 0.5%;">
                <v-btn class="maximum_red-fondo white-texto" @click="cerrar">
                    cerrar
                </v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<style>
.videos:hover {
    scale:1.1;
    transition: 0.2s;
}
</style>
<script>
import Fecha from '@/components/dashboard/Fecha.vue'
import Indicadores from '@/components/dashboard/Indicadores.vue'

export default {
    name:'Documentos',
    components:{Fecha,Indicadores},
    data() 
    {
        return {
            dataVideos : [
                {
                    url:'https://grupoatika.sharepoint.com/:v:/r/sites/AppAtika/Documentos%20compartidos/VideoTutoriales/Videotutorial%20configuraci%C3%B3n%20de%20tel%C3%A9fono.mp4?csf=1&web=1&e=Rxrdlh',
                    titulo:'Aprende a configurar tu nuevo teléfono IP',
                    miniatura: './imagenes/videos/telefono.jpg'
                },
                {
                    url:'https://grupoatika.sharepoint.com/:v:/r/sites/AppAtika/Documentos%20compartidos/VideoTutoriales/Explicaci%C3%B3n%20uso%20de%20Sala%20en%20Outlook.mp4?csf=1&web=1&e=gIiZDh',
                    titulo:'Agenda una sala de reuniones en Outlook',
                    miniatura: './imagenes/videos/agenda.jpg'
                },
                {
                    url:'https://grupoatika.sharepoint.com/:v:/r/sites/AppAtika/Documentos%20compartidos/Capsulas/Capsula_Mejoras_Odoo_Formato.mkv?csf=1&web=1&e=OGMOGB',
                    titulo:'Capsula informativa mejoras: Odoo V1',
                    miniatura: './imagenes/videos/CapsulaOdoo.jpeg'
                },
                {
                    url:'https://grupoatika.sharepoint.com/:v:/r/sites/AppAtika/Documentos%20compartidos/Capsulas/Capsula_Proceso_Productos_Gen%C3%A9ricos.mp4?csf=1&web=1&e=o6Fyvo',
                    titulo:'Capsula Proceso Productos Genericos',
                    miniatura: './imagenes/videos/Capsula_Productos_Genéricos_Cotizacion.png'
                },
                {
                    url:'https://grupoatika.sharepoint.com/:v:/r/sites/AppAtika/Documentos%20compartidos/Capsulas/Proceso_Filtrar_Gestionar_Proyectos.mp4?csf=1&web=1&e=9vte4C',
                    titulo:'Proceso Filtrar y Gestionar Proyectos',
                    miniatura: './imagenes/videos/Capsula_Filtrar_gestionar_proyectos.png'
                }
            ]
        }
    }, 
    methods: {
        cerrar(){
            this.$emit('cerrarV','1');
        }
    }   
}
</script>