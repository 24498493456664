<template>
    <v-app>
  
    <v-container class="white-fondo" fluid>
        <!--
        <v-card>
            <v-card-actions>
            --> 
                        <!--
                        <v-btn
                            class="maximum_red-fondo white-texto"
                            v-on:click="registrarDatos">
                            Confirmar
                        </v-btn>
                        
                        <v-btn class="black-coral-fondo white-texto"
                            v-on:click="limpiarDatos">
                            Limpiar
                        </v-btn>
                        -->
                        <!--
                        <v-btn
                            class="mandarin-fondo jet-texto"
                            v-on:click="volverHome">
                            Regresar al home
                        </v-btn>
                    -->
            <!--
            </v-card-actions>
        </v-card>
    -->
       
        <v-card
            elevation="6"
            class="white-fondo"
        >

            <v-card-title>
                Datos de aduana
            </v-card-title>
                <v-card-text>
                    <v-row>

                    <v-col>
                            <v-btn
                            class="jet-fondo white-texto"
                            v-on:click="dialogAdValorem"
                            >
                            Ad Valorem
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            class="jet-fondo white-texto"
                            v-on:click="dialogSeguroImportacion"
                            >
                            Seguro de importación
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
        <br />
        <br />

        <v-card elevation="6" outline>

            <v-card-title>
                Datos de flete
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-btn
                            class="jet-fondo white-texto"
                            v-on:click="dialogFleteImportacion"
                            >
                            Flete de importación
                        </v-btn>
                    </v-col>
                     <v-col>
                        <v-btn
                            class="jet-fondo white-texto"
                            v-on:click="dialogFleteNacional"
                            >
                            Flete nacional
                        </v-btn>
                    </v-col>
                      <v-col>
                        <v-btn
                            class="jet-fondo white-texto"
                            v-on:click="dialogGastosAduaneros"
                            >
                            Gastos aduaneros
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>        

        <!-- Dialogo ad valorem-->
        <v-dialog v-model="windowAdValorem"
            width="600"
            transition="dialog-top-transition"
            persistent>
            <adValorem  @objAdValorem="rspAdValorem" class="dialogComex"></adValorem>
        </v-dialog>


        <!-- Dialogo seguro de importacion-->
        <v-dialog v-model="windowSeguroImportacion"
            width="600"
            transition="dialog-top-transition"
            persistent>
            <seguroImportacion  @objSeguroImportacion="rspSeguroImportacion" class="dialogComex"></seguroImportacion>
        </v-dialog>

        <!-- Dialogo flete de importacion-->
        <v-dialog v-model="windowFleteImportacion"
            width="600"
            height="50"
            style="height:150px !important"
            transition="dialog-top-transition"
            persistent>
            <fleteImportacion  @objFleteImportacion="rspFleteImportacion" class="dialogComex"></fleteImportacion>
        </v-dialog>

        <!-- Dialogo flete nacional-->
        <v-dialog v-model="windowFleteNacional"
            width="600"
            style="height:150px !important"
            transition="dialog-top-transition"
            persistent>
            <fleteNacional @objFleteNacional="rspFleteNacional" class="dialogComex"></fleteNacional>
        </v-dialog>

        <v-dialog v-model="windowGastosAduaneros"
            width="600"
            transition="dialog-top-transition"
            persistent>
            <gastosAduaneros @objGastosAduaneros="rspGastosAduaneros" class="dialogComex"></gastosAduaneros>
        </v-dialog>

        <v-snackbar
            dark
            block
            color="green darken-2"
            v-model="mensajeSnack"
            >
                <strong>
                        {{AvisoSnack}}
                </strong>

        </v-snackbar>

</v-container>
</v-app>
</template>
<style>
</style>
<script>
import Header from '@/components/Header.vue'
import adValorem from '@/components/comex/adValorem.vue'
import seguroImportacion from '@/components/comex/seguroImportacion.vue'
import fleteImportacion from '@/components/comex/fleteImportacion.vue'
import fleteNacional from '@/components/comex/fleteNacional.vue'
import gastosAduaneros from '@/components/comex/gastosAduaneros.vue'

import axios from 'axios'




export default {
    name:'formularioComex',

    data() {
        return {
            dataOrigenMarca:[],
            comex: {
                    adValorem: {

                    },

                    seguroImportacion: {

                    },

                    fleteImportacion : {

                    },

                    fleteNacional: {
                    },

                    gastosAduaneros: {

                    }
            },

            windowAdValorem:false,
            windowSeguroImportacion:false,
            windowFleteImportacion:false,
            windowFleteNacional:false,
            windowGastosAduaneros:false,
            confirmarBorrado:false,
            mensajeSnack:false,
            AvisoSnack:''
        }
    },

    components : {
        adValorem,
        seguroImportacion,
        fleteImportacion,
        fleteNacional,
        gastosAduaneros,
        Header,
    },

    created() {

    },

    methods: {

        //Dialog : Estos mantienen las operaciones de abrir y cerrar ventanas

        dialogAdValorem: function() {
            this.windowAdValorem = true;
        },

        dialogSeguroImportacion: function() {
            this.windowSeguroImportacion = true
        },

        dialogFleteImportacion: function() {
            this.windowFleteImportacion = true
        },

        dialogFleteNacional: function() {
            this.windowFleteNacional = true
        },

        dialogGastosAduaneros: function() {
            this.windowGastosAduaneros = true
        },

        //RSP : Estas funciones mandan los datos ingresados de regreso de las ventanas

        rspAdValorem: function(value) {
            this.comex.adValorem=value
            this.windowAdValorem = false
            if(value)
            {
            this.registrarDatos()
            }
        },

        rspSeguroImportacion: function(value) {
            this.comex.seguroImportacion=value
            this.windowSeguroImportacion=false
            if(value)
            {
            this.registrarDatos()
            }
        },

        rspFleteImportacion: function(value) {
            this.windowFleteImportacion = false
            this.comex.fleteImportacion=value
            if (value)
            {
            this.registrarDatos()
            }
        },

        rspFleteNacional: function(value) {

            this.windowFleteNacional = false
            this.comex.fleteNacional=value
            if(value) {
            this.registrarDatos()
            }
        },

         rspGastosAduaneros: function(value) {
            this.windowGastosAduaneros = false
            this.comex.gastosAduaneros=value
            if(value)
            {
            this.registrarDatos()
            }
        },

        desactivaAdValorem: function() {
            this.sinAdValorem=!this.sinAdValorem
            this.comex.conAdValorem=!this.sinAdValorem

        },

        muestraValor() {
            //console.log("Obj Comex")
            //console.log(this.comex)
        },

        registrarDatos() {
            this.muestraValor()
            axios.post(
                process.env.VUE_APP_RUTA_COMEX,{
                method:'post',
                accion:'registrar',
                obj:this.comex
                }).then(response => {
                    //console.log("Respuesta axios")
                    //console.log(response.data)
                    if(!(this.comex === null))
                    {
                        this.mensajeSnack=true
                        this.AvisoSnack="Datos guardados"
                    }
                    
                })
        },

        limpiarDatos() {
          //console.log("limpiar datos")

          this.$refs['Origen'].value= ""

        },

        volverHome() {
            this.$router.push('/Home')
        }


    }
}
</script>