<template>
     <v-card>
            <v-card-title>
                Gestion módulos de {{nombreUsuario}}
            </v-card-title>
            <v-card-text>
                <br>
                <v-btn @click="abrirAgregarModulo()" class="maximum_red-fondo white-texto">Agregar módulo</v-btn>
                <br>
                <br>
                <v-data-table
                    :headers="headersApps"
                    :items="dataApps"
                    height="475"
                    :fixed-header=true
                    :footer-props="{'items-per-page-options':[50]}"
                >

                <template v-slot:item.acciones="{item}">

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on:click="abrirReemplazoApp(item.id_ua, item.nombreApp)" class="maximum_red-fondo white-texto" v-bind="attrs" v-on="on">
                                <v-icon>
                                    mdi-swap-vertical
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Reemplazar módulo</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on:click="abrirConfirmacionEliminar(item.id_ua)" class="ml-2" color="#A3A3A3" v-bind="attrs" v-on="on">
                                <v-icon>
                                    mdi-delete-forever
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar permiso módulo</span>
                    </v-tooltip>
            
                </template>

                </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-btn @click="enviarCerrarGestionModulos()" class="maximum_red-fondo white-texto">
                    cerrar
                </v-btn>
            </v-card-actions>

            <v-dialog v-model="dialogReemplazo" width="800px" persistent>
                <v-card>
                    <v-card-title>
                    </v-card-title>
                    <v-card-text>
                        <h3>Reemplazar modulo</h3>
                        <br>
                        <h5>Modulo actual</h5>
                        <v-text-field v-model="nombreAppActual"></v-text-field>
                        <br>
                        <h5>lista de Modulos</h5>
                        <v-select
                            v-model="idApp"
                            :items="listaApps"
                            item-text="nombreApp"
                            item-value="id_app"
                            label="seleccione un nuevo modulo">
                        </v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="reemplazarApp()" class="maximum_red-fondo white-texto">
                            Guardar
                        </v-btn>
                        <v-btn @click="cerrarReemplazoApp()" class="ml-2 white-texto" color="#A3A3A3">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAgregarModulo" width="800px" persistent>
                <v-card>
                    <v-card-title>
                        Agregar módulo
                    </v-card-title>
                    <v-card-text>
                        <h3>Seleccionar módulo</h3>
                        <v-select
                            v-model="idApp"
                            :items="listaApps"
                            item-text="nombreApp"
                            item-value="id_app"
                            label="seleccione un modulo">
                        </v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="agregarModulo()" class="maximum_red-fondo white-texto">
                            Agregar
                        </v-btn>
                        <v-btn @click="cerrarAgregarModulo()" class="ml-2 white-texto" color="#A3A3A3">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfirmacionEliminarModulo" width="500px" persistent>
                <v-card >
                    <v-card-title class="justify-center">
                        <v-icon class="icon-vertical-align" x-large color="red">
                                mdi-alert
                            </v-icon>
                    </v-card-title>
                    <v-card-text class="text-center">
                        <br>
                        <h4>¿ Esta seguro que desea eliminar el acceso a este modulo ?</h4>
                    </v-card-text>
                    <v-card-actions class="text-center">
                        <v-flex class="px-5 pb-1">
                            <v-btn class="maximum_red-fondo white-texto" @click="eliminarModulo()">Eliminar</v-btn>
                        </v-flex>
                        <v-flex class="px-1 pb-1">
                            <v-btn class="ml-2 white-texto" color="#A3A3A3" @click="cerrarConfirmacionEliminar()">Cancelar</v-btn>
                        </v-flex> 
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        
</template>

<script>
import axios from 'axios';

export default{
    component:'UsuariosApps', 

    data() {
        return {
            dialogAgregarModulo:false,
            dialogReemplazo:false,
            dialogConfirmacionEliminarModulo:false,
            headersApps: [
                { text:'Nombre Modulo', value:'nombreApp',},
                { text:'Roles', value:'roles',},
                { text:'Acciones', value:'acciones',}
            ],
            nombreAppActual:'',
            idApp:0,
            idRegistro:0,
            dataApps:[],    
            listaApps:[], 
            model:true
        }
    },
    props: {
        idUsuario: 0,
        nombreUsuario: ''
    },
    watch: {
        idUsuario: function(){
            this.obtenerAppsUsuario();
        }
    },
    created() {
        this.obtenerAppsUsuario();
        this.obtenerApps();
    },
    
    methods: {
        obtenerAppsUsuario: function(){
            var ruta = process.env.VUE_APP_API_USUARIOS_OBTENER_APPS;
            //console.log(ruta);
            let id_usuario = this.idUsuario;
            let datos = {
                id_usuario
            }
            axios.post(ruta,datos).then(response=>{
                this.dataApps=response.data;
                //console.log(this.dataApps);
            })
        },
        obtenerApps:function(){
            var ruta = process.env.VUE_APP_API_LISTADO_APPS;
            let id_usuario = this.idUsuario;
            let datos = {
                id_usuario
            }
            //console.log(ruta);
            axios.get(ruta).then(response=>{ //cambiar a post cuando se tenga lista la query
                this.listaApps=response.data;
                //console.log(this.listaApps);
            })
        },
        abrirReemplazoApp: function(idUA,nombreApp){
            this.dialogReemplazo=true;
            this.nombreAppActual=nombreApp;
            this.idRegistro=idUA;
        },
        reemplazarApp:function(){
            
            let id_ua = this.idRegistro;
            let id_app = this.idApp;
            let datos = {
                id_ua,
                id_app
            }
            let ruta = process.env.VUE_APP_API_USUARIOS_REEMPLAZAR_MODULO;
            axios.post(ruta,datos).then(response => {
                let respuesta=response.data
                //console.log(respuesta); 
                if(respuesta==1){
                    this.obtenerAppsUsuario();
                    this.cerrarReemplazoApp();
                }
            });
        },
        cerrarReemplazoApp: function(){
            this.dialogReemplazo=false;
            this.nombreAppActual='';
            this.nombreApp='';
            this.idUA=0;
            this.idApp=0;
        },
        enviarCerrarGestionModulos: function(){
            this.$emit('cerrarModulos');
        },
        abrirAgregarModulo: function(){
            this.dialogAgregarModulo=true;
        },
        cerrarAgregarModulo: function(){
            this.dialogAgregarModulo=false;
            this.idApp=0;
        },
        agregarModulo:function(){
            let id_usuario = this.idUsuario;
            let id_app = this.idApp;
            let datos = {
                id_usuario,
                id_app
            }
            let ruta = process.env.VUE_APP_API_USUARIOS_AGREGAR_MODULO;
            axios.post(ruta,datos).then(response => {
                let respuesta=response.data
                //console.log(respuesta); 
                if(respuesta==1){
                    this.obtenerAppsUsuario();
                    this.cerrarAgregarModulo();
                }
            })
        },
        abrirConfirmacionEliminar: function(idUA){
            this.dialogConfirmacionEliminarModulo=true;
            this.idRegistro=idUA;
        },
        cerrarConfirmacionEliminar: function(){
            this.dialogConfirmacionEliminarModulo=false;
            this.idRegistro=0;
        },
        eliminarModulo(){
            let id_ua = this.idRegistro;
            let datos = {
                id_ua
            }
            let ruta = process.env.VUE_APP_API_USUARIOS_ELIMINAR_MODULO;
            axios.post(ruta,datos).then(response => {
                let respuesta=response.data
                //console.log(respuesta); 
                if(respuesta==1){
                    this.obtenerAppsUsuario();
                    this.cerrarConfirmacionEliminar();
                }
            })
        },
        obtenerPermisos: function() {
            //console.log("obtener Permisos")
            axios.get(process.env.VUE_APP_API_APP_PERMISOS).then(response => {
                this.dataPermisos=response.data
                //console.log(this.dataPermisos);
            })
        },
    }
}
</script>